<template>
  <div class="components-page" v-loading="loading">
    <div class="main-wrap flex flex-dc">
      <div class="content-title-wrap flex flex-ac flex-jb">
        <div class="title">聯繫人資訊 ({{ contactList.length }})</div>
        <div class="tooltip-wrap">
          <el-input placeholder="Search" v-model="search" clearable class="input-with-select">
            <i slot="prepend" class="el-icon-search"></i>
            <div slot="append" class="flex flex-ac">
              <i class="el-icon-sort pointer mr10"></i>
              <i class="el-icon-s-operation pointer"></i>
            </div>
          </el-input>
        </div>
      </div>

      <div class="card-content flex flex-ww">
        <div v-if="!addNew" class="form-card-wrap flex flex-ac flex-jc">
          <i class="el-icon-circle-plus-outline pointer" style="font-size:calc(2vw + 20px); color:#999"
             @click="newContact"></i>
        </div>

        <div v-if="addNew" class="form-card-wrap">
          <div class="form-wrap global-form-wrap">
            <div class="flex flex-1">
              <div class="left flex-dc">
                <div class="form-group flex flex-ac">
                  <div class="form-label">*中文名</div>
                  <div class="form-control flex-1">
                    <el-input v-model="newItem.name" placeholder="请输入内容" size="small"></el-input>
                  </div>
                </div>
                <div class="form-group flex flex-ac">
                  <div class="form-label">*英文名</div>
                  <div class="form-control flex-1">
                    <el-input v-model="newItem.nameEn" placeholder="请输入内容" size="small"></el-input>
                  </div>
                </div>
              </div>

              <div class="pic flex flex-ac ml15">
                <img src="@/assets/images/logo.png" width="60px">
              </div>
              <div class="btn flex flex-dc flex-ac flex-js ml15">
                <el-upload
                    ref="avatarUpload_new"
                    :action="uploadUrl"
                    :data="templateUploadData"
                    :headers="uploadHeaders"
                    :on-success="handleUploadAvatarSuccess"
                    :on-error="handleUploadAvatarError"
                    :show-file-list="false"
                    :limit="1"
                    :file-list="avatarList">
                  <i class="el-icon-upload pointer" style="font-size:calc(0.8vw + 8px); color:#555"></i>
                </el-upload>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*職稱</div>
              <div class="form-control flex-1">
                <el-input v-model="newItem.title" placeholder="请输入内容" size="small"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*權力類別</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.powerType" placeholder="请选择" size="small" style="width:100%">
                  <el-option label="引薦者" value="REFERRAL"></el-option>
                  <el-option label="影響者" value="INFLUENCE"></el-option>
                  <el-option label="決策者" value="DECISION"></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*責任類別</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.position" placeholder="请选择" size="small" style="width:100%">
                  <el-option label="操作者" value="OPERATOR"></el-option>
                  <el-option label="主管" value="SUPERVISOR"></el-option>
                  <el-option label="經營" value="BUSINESS"></el-option>
                  <el-option label="經理" value="MANGER"></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">信箱</div>
              <div class="form-control flex-1">
                <el-input v-model="newItem.email" placeholder="请输入内容" size="small"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*性別</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.gender" placeholder="请选择" size="small" style="width:100%">
                  <el-option label="未知" :value="0"></el-option>
                  <el-option label="男" :value="1"></el-option>
                  <el-option label="女" :value="2"></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">生日</div>
              <div class="form-control flex-1">
                <el-date-picker
                    v-model="newItem.birthday"
                    type="date"
                    size="small"
                    placeholder="请选择"
                    style="width: 100%;"
                    value-format="yyyy-MM-dd">
                </el-date-picker>

              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">個性</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.personality" multiple filterable allow-create default-first-option
                           placeholder="请选择" size="small" style="width:100%">
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">嗜好</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.hobbies" multiple filterable allow-create default-first-option
                           placeholder="请选择"
                           size="small" style="width:100%">
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">教育</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.education" filterable allow-create default-first-option placeholder="请选择"
                           size="small" style="width:100%">
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">家庭</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.maritalStatus" filterable default-first-option
                           placeholder="请选择" size="small" style="width:100%">
                  <el-option label="未婚" value="UNMARRIED"></el-option>
                  <el-option label="已婚" value="MARRIED"></el-option>
                  <el-option label="離異" value="DIVORCE"></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">居住地</div>
              <div class="form-control flex-1">
                <el-input v-model="newItem.address" placeholder="请输入内容" size="small"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">工作經歷</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.workExperience" multiple filterable allow-create default-first-option
                           placeholder="请选择" size="small" style="width:100%">
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">來源</div>
              <div class="form-control flex-1">
                <el-select v-model="newItem.source" filterable default-first-option placeholder="请选择"
                           size="small" style="width:100%">
                  <el-option label="馬太" value="MATTHEWPI"></el-option>
                  <el-option label="網路" value="INTERNET"></el-option>
                  <el-option label="電話開發" value="PHONE"></el-option>
                  <el-option label="客戶介紹" value="INTRODUCTION"></el-option>
                  <el-option label="內部資料" value="INTERNAL_MATERIALS"></el-option>
                </el-select>
              </div>
            </div>

            <div v-for="(im, imIndex) in newItemImList" :key="'newItemIm_' + imIndex" class="form-group flex flex-ac">
              <div class="form-label flex flex-jb">
                <span v-if="imIndex === 0">聯絡方式</span>
                <span v-else> </span>
                <i v-if="imIndex === 0" class="el-icon-plus fw pointer mr10" @click="newItemImList.push({})"></i>
                <i v-if="newItemImList.length > 1" class="el-icon-minus fw pointer mr10"
                   @click="newItemImList.splice(imIndex, 1)"></i>
              </div>
              <div class="form-control flex-1 flex">
                <el-select v-model="im.type" filterable allow-create default-first-option placeholder="请选择"
                           size="small" class="mr10" style="width:40%">
                  <el-option label="Phone" value="PHONE"></el-option>
                  <el-option label="WeChat" value="WECHAT"></el-option>
                  <el-option label="Line" value="LINE"></el-option>
                  <el-option label="X(Twitter)" value="TWITTER"></el-option>
                  <el-option label="Facebook" value="FACEBOOK"></el-option>
                  <el-option label="Linkedin" value="LINKEDIN"></el-option>
                  <el-option label="分機" value="TEL_EXT"></el-option>
                </el-select>
                <el-input class="flex-1" v-model="im.value" placeholder="请输入内容" size="small"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac flex-je">
              <el-button type="success" size="small" @click="save(newItem)">Save</el-button>
              <el-button type="info" size="small" @click="addNew=false">Cancels</el-button>
            </div>
          </div>
        </div>

        <div v-for="(contact, index) in contactList" :key="contact.id" class="form-card-wrap">
          <div class="form-wrap global-form-wrap">
            <div class="flex flex-1">
              <div class="left flex-dc">
                <div class="form-group flex flex-ac">
                  <div class="form-label">*中文名</div>
                  <div class="form-control flex-1">
                    <el-input v-model="contact.name" placeholder="请输入内容" size="small"
                              :disabled="!cardStatus['_' + index].isEdit"></el-input>
                  </div>
                </div>
                <div class="form-group flex flex-ac">
                  <div class="form-label">*英文名</div>
                  <div class="form-control flex-1">
                    <el-input v-model="contact.nameEn" placeholder="请输入内容" size="small"
                              :disabled="!cardStatus['_' + index].isEdit"></el-input>
                  </div>
                </div>
              </div>

              <div class="pic flex flex-ac ml15">
                <img v-if="contact.avatarUrl" :src="contact.avatarUrl" width="60px">
                <img v-else src="@/assets/images/logo.png" width="60px">
              </div>
              <div class="btn flex flex-dc flex-ac flex-js ml15">
                <i class="el-icon-edit pointer" style="font-size:calc(0.8vw + 8px); color:#555"
                   :class="{'text-success': cardStatus['_' + index].isEdit}"
                   @click="cardStatus['_' + index].isEdit = !cardStatus['_' + index].isEdit; avatar = null"></i>
                <el-upload
                    :ref="'avatarUpload_' + index"
                    v-show="cardStatus['_' + index].isEdit"
                    :action="uploadUrl"
                    :data="templateUploadData"
                    :headers="uploadHeaders"
                    :on-success="handleUploadAvatarSuccess"
                    :on-error="handleUploadAvatarError"
                    :show-file-list="false"
                    :limit="1"
                    :file-list="avatarList">
                  <i class="el-icon-upload pointer" style="font-size:calc(0.8vw + 8px); color:#555"></i>
                </el-upload>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*職稱</div>
              <div class="form-control flex-1">
                <el-input v-model="contact.title" placeholder="请输入内容" size="small"
                          :disabled="!cardStatus['_' + index].isEdit"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*權力類別</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.powerType" placeholder="请选择" size="small"
                           :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                  <el-option label="引薦者" value="REFERRAL"></el-option>
                  <el-option label="影響者" value="INFLUENCE"></el-option>
                  <el-option label="決策者" value="DECISION"></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*責任類別</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.position" placeholder="请选择" size="small"
                           :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                  <el-option label="操作者" value="OPERATOR"></el-option>
                  <el-option label="主管" value="SUPERVISOR"></el-option>
                  <el-option label="經營" value="BUSINESS"></el-option>
                  <el-option label="經理" value="MANGER"></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">信箱</div>
              <div class="form-control flex-1">
                <el-input v-model="contact.email" placeholder="请输入内容" size="small"
                          :disabled="!cardStatus['_' + index].isEdit"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*性別</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.gender" placeholder="请选择" size="small"
                           :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                  <el-option label="未知" :value="0"></el-option>
                  <el-option label="男" :value="1"></el-option>
                  <el-option label="女" :value="2"></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">生日</div>
              <div class="form-control flex-1">
                <el-date-picker
                    v-model="contact.birthday"
                    type="date"
                    size="small"
                    placeholder="请选择"
                    style="width: 100%;"
                    value-format="yyyy-MM-dd"
                    :disabled="!cardStatus['_' + index].isEdit">
                </el-date-picker>

              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">個性</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.personality" multiple filterable allow-create default-first-option
                           placeholder="请选择" size="small" :disabled="!cardStatus['_' + index].isEdit"
                           style="width:100%">
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">嗜好</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.hobbies" multiple filterable allow-create default-first-option
                           placeholder="请选择"
                           size="small" :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">教育</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.education" filterable allow-create default-first-option placeholder="请选择"
                           size="small" :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">家庭</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.maritalStatus" filterable default-first-option
                           placeholder="请选择" size="small" :disabled="!cardStatus['_' + index].isEdit"
                           style="width:100%">
                  <el-option label="未婚" value="UNMARRIED"></el-option>
                  <el-option label="已婚" value="MARRIED"></el-option>
                  <el-option label="離異" value="DIVORCE"></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">居住地</div>
              <div class="form-control flex-1">
                <el-input v-model="contact.address" placeholder="请输入内容" size="small"
                          :disabled="!cardStatus['_' + index].isEdit"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">工作經歷</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.workExperience" multiple filterable allow-create default-first-option
                           placeholder="请选择" size="small" :disabled="!cardStatus['_' + index].isEdit"
                           style="width:100%">
                </el-select>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">來源</div>
              <div class="form-control flex-1">
                <el-select v-model="contact.source" filterable default-first-option placeholder="请选择"
                           size="small" style="width:100%" :disabled="!cardStatus['_' + index].isEdit">
                  <el-option label="馬太" value="MATTHEWPI"></el-option>
                  <el-option label="網路" value="INTERNET"></el-option>
                  <el-option label="電話開發" value="PHONE"></el-option>
                  <el-option label="客戶介紹" value="INTRODUCTION"></el-option>
                  <el-option label="內部資料" value="INTERNAL_MATERIALS"></el-option>
                </el-select>
              </div>
            </div>

            <div v-for="(im, imIndex) in cardStatus['_' + index].imList" :key="index + 'im_' + imIndex"
                 class="form-group flex flex-ac">
              <div class="form-label flex flex-jb">
                <span v-if="imIndex === 0">聯絡方式</span>
                <span v-else> </span>
                <i v-if="imIndex === 0" class="el-icon-plus fw pointer mr10"
                   @click="cardStatus['_' + index].imList.push({})"></i>
                <i v-if="cardStatus['_' + index].imList.length > 1" class="el-icon-minus fw pointer mr10"
                   @click="cardStatus['_' + index].imList.splice(imIndex, 1)"></i>
              </div>
              <div class="form-control flex-1 flex">
                <el-select v-model="im.type" filterable allow-create default-first-option placeholder="请选择"
                           size="small" :disabled="!cardStatus['_' + index].isEdit" class="mr10" style="width:40%">
                  <el-option label="Phone" value="PHONE"></el-option>
                  <el-option label="WeChat" value="WECHAT"></el-option>
                  <el-option label="Line" value="LINE"></el-option>
                  <el-option label="X(Twitter)" value="TWITTER"></el-option>
                  <el-option label="Facebook" value="FACEBOOK"></el-option>
                  <el-option label="Linkedin" value="LINKEDIN"></el-option>
                  <el-option label="分機" value="TEL_EXT"></el-option>
                </el-select>
                <el-input class="flex-1" v-model="im.value" placeholder="请输入内容" size="small"
                          :disabled="!cardStatus['_' + index].isEdit"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac flex-je" v-show="cardStatus['_' + index].isEdit">
              <el-button type="success" size="small" @click="update(contact, index)">Save</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../styles/element-variables.scss';

.components-page::v-deep {
  overflow: hidden;
  height: 100%;

  .main-wrap {
    height: 100%;
    padding: 15px;

    .content-title-wrap {
      border-bottom: 1px solid #999;

      .title {
        font-size: calc(0.6vw + 6px);
        font-weight: bold;
        padding-left: 15px;
      }

      .tooltip-wrap {
        .el-input-group__prepend,
        .el-input__inner,
        .el-input-group__append {
          background-color: transparent;
          border: none;

          i {
            font-size: calc(0.7vw + 7px);
          }
        }
      }
    }

    .card-content {
      margin-top: 10px;
      overflow: auto;

      .form-card-wrap {
        padding: 15px;
        margin: 10px;
        box-shadow: 0 5px 5px rgba(#000, 0.2);
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        width: calc(50% - 20px);
        min-height: 150px;

        &.active {
          background-color: rgba($--color-primary, 0.1);
        }
      }

      .list-wrap {
        .list-item {
          margin-left: 2%;
          width: calc(2.2vw + 22px);
          height: calc(2.2vw + 22px);
          border-radius: 100%;
          border: 4px solid #999;
          color: #999;
          font-size: calc(0.6vw + 5px);
          font-weight: bold;
          cursor: pointer;

          &.active {
            background-color: $--color-primary;
            color: #fff;
            border-color: $--color-primary;
            box-shadow: 0 0 5px #555;
            text-shadow: 0 0 15px #fff;
          }
        }
      }
    }
  }
}
</style>

<script>
import * as contactApi from '@/apis/contact'
import {getToken} from "@/utils/auth";

export default {
  props: ["companyId"],
  data() {
    return {
      loading: false,
      cardStatus: {
        _0: {
          isEdit: false,
          imList: [{}]
        }
      },
      search: '',
      addNew: false,
      newItem: null,
      newItemImList: [],
      contactList: [],
      uploadUrl: process.env.VUE_APP_BASE_API + '/basic/info/upload',
      templateUploadData: {
        typeId: 50
      },
      uploadHeaders: {
        'S-Token': getToken()
      },
      avatarList: [],
      avatar: null,
    }
  },
  watch: {
    search: function () {
      this.loadList();
    }
  },
  created() {
    this.init();
  },
  methods: {
    // 數據修改後廣播給父級
    // this.$emit('closeDrawer', 'connectUserDrawer');
    init() {
      this.loadList()
    },
    loadList() {
      this.loading = true;
      this.avatar = null;
      contactApi.listByCompanyId(this.companyId, this.search).then(data => {
        const cardStatus = {}
        for (let index = 0; index < data.length; index++) {
          cardStatus['_' + index] = {
            isEdit: false,
            imList: [{}]
          }
        }
        this.cardStatus = cardStatus;
        this.contactList = data;
        for (let index = 0; index < data.length; index++) {
          if (data[index].im && data[index].im.length > 0) {
            cardStatus['_' + index].imList = data[index].im
          }
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    newContact() {
      this.newItem = {}
      this.newItemImList = [{}]
      this.addNew = true;
    },
    save() {
      this.loading = true;

      // 格式化下 im 列表
      const imList = this.newItemImList.filter(item => item.type && item.value);
      this.newItem.im = JSON.stringify(imList);

      if (this.avatar) {
        this.newItem.avatarUrl = this.avatar;
      }

      // 新增
      contactApi.add(this.companyId, this.newItem).then(() => {
        this.addNew = false;
        this.loadList();
      }).catch(() => {
        this.loading = false;
      })
    },
    update(contact, index) {
      this.loading = true;

      // 格式化下 im 列表
      const imList = this.cardStatus['_' + index].imList.filter(item => item.type && item.value);
      contact.im = JSON.stringify(imList);

      if (this.avatar) {
        contact.avatarUrl = this.avatar;
      }

      // 更新
      contactApi.update(contact).then(() => {
        this.cardStatus['_' + index].isEdit = false;
        this.loadList();
      }).catch(() => {
        this.loading = false;
      })
    },
    handleUploadAvatarSuccess(res, file) {
      if (res.code !== 0) {
        this.$message({
          message: res.msg || '文件上传失败',
          type: 'error'
        });
        return;
      }

      this.avatar = file;
      this.avatarList = [];
    },
    handleUploadAvatarError() {
      this.avatarList = [];
    }
  }
}
</script>
