import request from './request';

export function listSimple(search, orderBy) {
  return request({
    url: `/crm/contact/simple`,
    method: 'get',
    params: {search, orderBy}
  })
}

export function listByCompanyId(companyId, search) {
  return request({
    url: `/crm/contact/company/${companyId}`,
    method: 'get',
    params: {search}
  })
}

export function add(companyId, data) {
  return request({
    url: `/crm/contact/company/${companyId}`,
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/crm/contact/${data.id}`,
    method: 'put',
    data
  })
}

