<template>
  <div class="components-page" v-loading="loading">
    <div class="main-wrap flex flex-dc">
      <div class="content-title-wrap flex flex-ac flex-jb">
        <div class="title">生意機會</div>
      </div>

      <div class="content-wrap flex">
        <div class="content-group-wrap flex flex-dc">
          <div class="table-title-wrap flex flex-ac">
            <img src="@/assets/images/icon-16.png">
            <span class="text">倉庫</span>
          </div>

          <div class="table-handle-wrap flex flex-ac flex-je">
            <i class="icon el-icon-circle-plus-outline" @click="unshiftNewHub()"></i>
          </div>

          <!-- table-wrap start -->
          <div class="table-wrap flex flex-dc flex1">
            <div class="table-group">
              <table class="global-table">
                <tr>
                  <th>操作</th>
                  <th width="15%">倉庫型態</th>
                  <th width="15%">倉庫地区</th>
                  <th width="15%">倉庫地點</th>
                  <th width="15%">服務商</th>
                  <th width="15%">機會</th>
                  <th width="15%">新增日期</th>
                </tr>
              </table>
            </div>

            <div class="table-group flex1" style="overflow-y:auto">
              <table class="global-table">
                <tr v-if="!hubData.length">
                  <td colspan="99">
                    <el-empty description="No Data"></el-empty>
                  </td>
                </tr>
                <tr v-for="(item, index) of hubData" :key="'hub' + index + item.id">
                  <td>
                    <i class="el-icon-edit pointer mr10" :class="{'text-success': item.isEdit}"
                       @click="item.isEdit = !item.isEdit"></i>
                    <i class="el-icon-check pointer" :class="{'text-success': item.isEdit}" v-show="item.isEdit"
                       @click="saveHub(item)"></i>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{
                        item?.serviceType === 101 ? '自有倉' : (item?.serviceType === 102 ? '外包倉' : '其他')
                      }}</span>
                    <el-select v-model="item.serviceType" size="mini" style="width: 100%;"
                               v-show="item.isEdit">
                      <el-option label="自有倉" :value="101"></el-option>
                      <el-option label="外包倉" :value="102"></el-option>
                    </el-select>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item.country }}</span>
                    <el-select v-model="item.countryId" size="mini" style="width: 100%;"
                               v-show="item.isEdit">
                      <el-option v-for="c in countryList" :key="'hub' + index + 'c' +c.id"
                                 :label="c.name" :value="c.id"></el-option>
                    </el-select>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item.address }}</span>
                    <el-input v-model="item.address" placeholder="请输入内容" size="small"
                              v-show="item.isEdit"></el-input>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item.serviceProviderName }}</span>
                    <el-input v-model="item.serviceProviderName" placeholder="请输入内容" size="small"
                              v-show="item.isEdit"></el-input>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item?.haveChange ? '有' : '無' }}</span>
                    <el-select v-model="item.haveChange" size="mini" style="width: 100%;"
                               v-show="item.isEdit">
                      <el-option label="無" :value="false"></el-option>
                      <el-option label="有" :value="true"></el-option>
                    </el-select>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item.createdAt }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- table-wrap end -->
        </div>

        <div class="content-group-wrap flex flex-dc">
          <div class="table-title-wrap flex flex-ac">
            <img src="@/assets/images/icon-17.png">
            <span class="text">運輸</span>
          </div>

          <div class="table-handle-wrap flex flex-ac flex-je">
            <i class="icon el-icon-circle-plus-outline" @click="unshiftNewTransport()"></i>
          </div>

          <!-- table-wrap start -->
          <div class="table-wrap flex flex-dc flex1">
            <div class="table-group">
              <table class="global-table">
                <tr>
                  <th>操作</th>
                  <th width="15%">運輸型態</th>
                  <th width="15%">啟運地</th>
                  <th width="15%">目的地</th>
                  <th width="15%">服務商</th>
                  <th width="15%">機會</th>
                  <th width="15%">新增日期</th>
                </tr>
              </table>
            </div>

            <div class="table-group flex1" style="overflow-y:auto">
              <table class="global-table">
                <tr v-if="!transportData.length">
                  <td colspan="99">
                    <el-empty description="No Data"></el-empty>
                  </td>
                </tr>
                <tr v-for="(item) of transportData" :key="item.id">
                  <td>
                    <i class="el-icon-edit pointer mr10" :class="{'text-success': item.isEdit}"
                       @click="item.isEdit = !item.isEdit"></i>
                    <i class="el-icon-check pointer" :class="{'text-success': item.isEdit}" v-show="item.isEdit"
                       @click="saveTransport(item)"></i>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">空運</span>
                    <el-select v-model="item.serviceType" size="mini" style="width: 100%;"
                               v-show="item.isEdit">
                      <el-option label="空運" :value="200"></el-option>
                    </el-select>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item.originLocation }}</span>
                    <el-input v-model="item.originLocation" placeholder="请输入内容" size="small"
                              v-show="item.isEdit"></el-input>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item.destinationLocation }}</span>
                    <el-input v-model="item.destinationLocation" placeholder="请输入内容" size="small"
                              v-show="item.isEdit"></el-input>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item.serviceProviderName }}</span>
                    <el-input v-model="item.serviceProviderName" placeholder="请输入内容" size="small"
                              v-show="item.isEdit"></el-input>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item?.haveChange ? '有' : '無' }}</span>
                    <el-select v-model="item.haveChange" size="mini" style="width: 100%;"
                               v-show="item.isEdit">
                      <el-option label="無" :value="false"></el-option>
                      <el-option label="有" :value="true"></el-option>
                    </el-select>
                  </td>
                  <td width="15%">
                    <span v-show="!item.isEdit">{{ item.createdAt }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- table-wrap end -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../styles/element-variables.scss';

.components-page::v-deep {
  overflow: hidden;
  height: 100%;

  .main-wrap {
    height: 100%;
    padding: 15px;

    .content-title-wrap {
      border-bottom: 1px solid #999;
      padding-bottom: 10px;

      .title {
        font-size: calc(0.6vw + 6px);
        font-weight: bold;
        padding-left: 15px;
      }
    }

    .content-wrap {
      margin-top: 20px;
      overflow: hidden;

      .content-group-wrap {
        width: 50%;
        height: 100%;
        overflow: hidden;
        padding: 10px;

        .table-title-wrap {
          margin-bottom: 10px;
          font-size: calc(0.6vw + 6px);
          font-weight: bold;

          img {
            width: calc(1vw + 10px);
            margin-right: 10px;
          }
        }

        .table-handle-wrap {
          margin-bottom: 10px;

          .icon {
            font-size: calc(1vw + 10px);
            color: #555;
          }
        }

        .table-wrap {
          overflow: hidden;
        }
      }
    }
  }
}
</style>

<script>
import * as basicInfoApi from "@/apis/basic-info";
import * as companyChanceApi from "@/apis/company-chance";

export default {
  props: ["companyId"],
  data() {
    return {
      loading: false,
      hubData: [],
      transportData: [],
      countryList: [],
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadCountry();
      this.loadHubData();
      this.loadTransportData()
    },
    loadHubData() {
      this.loading = true;

      companyChanceApi.listHubList(this.companyId).then(res => {
        res.forEach(item => item.isEdit = false);
        this.hubData = res;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadTransportData() {
      this.loading = true;

      companyChanceApi.listTransportList(this.companyId).then(res => {
        res.forEach(item => item.isEdit = false);
        this.transportData = res;
      }).finally(() => {
        this.loading = false;
      });

    },
    loadCountry() {
      basicInfoApi.getCountryList().then(res => {
        this.countryList = res;
      })
    },
    unshiftNewHub() {
      this.hubData.forEach(item => {
        item.isEdit = false;
      })
      this.hubData.unshift({isEdit: true})
    },
    saveHub(item) {
      if (item.id) {
        this.updateHub(item);
      } else {
        this.addHub(item);
      }
    },
    addHub(item) {
      this.loading = true;

      item.companyId = this.companyId;

      companyChanceApi.addHub(item).then(() => {
        this.loadHubData();
      }).finally(() => {
        this.loading = false;
      });
    },
    updateHub(item) {
      this.loading = true;
      companyChanceApi.updateHub(item).then(() => {
        this.loadHubData();
      }).finally(() => {
        this.loading = false;
      });

    },
    unshiftNewTransport() {
      this.transportData.forEach(item => {
        item.isEdit = false;
      })

      this.transportData.unshift({
        isEdit: true,
        serviceType: 200
      })
    },
    saveTransport(item) {
      if (item.id) {
        this.updateTransport(item);
      } else {
        this.addTransport(item);
      }
    },
    addTransport(item) {
      this.loading = true;

      item.companyId = this.companyId;

      companyChanceApi.addTransport(item).then(() => {
        this.loadTransportData();
      }).finally(() => {
        this.loading = false;
      });
    },
    updateTransport(item) {
      this.loading = true;
      companyChanceApi.updateTransport(item).then(() => {
        this.loadTransportData();
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
