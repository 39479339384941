import request from './request';

export function listByCompanyIdAndType(companyId, contactType, search) {
  return request({
    url: `/crm/contact-log/company/${companyId}/contactType/${contactType}`,
    method: 'get',
    params: {search}
  })
}

export function add(companyId, contactType, data) {
  return request({
    url: `/crm/contact-log/company/${companyId}/contactType/${contactType}`,
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/crm/contact-log/${data.id}`,
    method: 'put',
    data
  })
}

