import request from './request';

/**
 * 获取生意机会 - 仓库列表
 */
export function listHubList(companyId) {
    return request({
        url: `/crm/company/${companyId}/chance/100`,
        method: 'get'
    })
}

/**
 * 添加新生意机会 - 仓库
 */
export function addHub(data) {
    return request({
        url: `/crm/company/${data.companyId}/chance/100`,
        method: 'post',
        data
    })
}

/**
 * 更新新生意机会 - 仓库
 */
export function updateHub(data) {
    return request({
        url: `/crm/company/chance/${data.id}`,
        method: 'put',
        data
    })
}

/**
 * 获取生意机会 - 运输列表
 */
export function listTransportList(companyId) {
    return request({
        url: `/crm/company/${companyId}/chance/200`,
        method: 'get'
    })
}

/**
 * 添加新生意机会 - 运输
 */
export function addTransport(data) {
    return request({
        url: `/crm/company/${data.companyId}/chance/200`,
        method: 'post',
        data
    })
}

/**
 * 更新新生意机会 - 运输
 */
export function updateTransport(data) {
    return request({
        url: `/crm/company/chance/${data.id}`,
        method: 'put',
        data
    })
}