import request from './request';

/**
 * 获取更新日志
 */
export function list(companyId) {
    return request({
        url: `/crm/update-log`,
        method: 'get',
        params: {companyId}
    })
}