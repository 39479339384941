import request from './request';

// --------------------------------- 客戶 開始
/**
 * 列出所有的 Customer (基礎信息)
 */
export function getaAllCustomer() {
  return request({
    url: `/customer/customerInfo/basic?hubId=`,
    method: 'get'
  })
}

/**
 * 拿的所有 endCustomer (基礎信息)
 * @returns {AxiosPromise}
 */
export function getEndCustomerList() {
  return request({
    url: `/customer/customerInfo/basic?type=0`,
    method: 'get'
  })
}

/**
 * 拿當前倉庫的所有 supplier (基礎信息)
 * @returns {AxiosPromise}
 */
export function getSupplierList() {
  return request({
    url: `/customer/customerInfo/basic?type=1`,
    method: 'get'
  })
}

/**
 * 拿當前倉庫的所有 consignee (基礎信息)
 * @returns {AxiosPromise}
 */
export function getConsigneeList() {
  return request({
    url: `/customer/customerInfo/basic?type=2`,
    method: 'get'
  })
}