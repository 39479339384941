import request from './request';

/**
 * 获取简单公司列表
 *
 * @param search 搜索内容
 */
export function listSimple(search, isFavorite) {
    return request({
        url: `/crm/company/list/simple`,
        method: 'get',
        params: {search, isFavorite}
    })
}
/**
 * 按修改顺序获取简单公司列表
 *
 * @param search 搜索内容
 */
export function listSimpleByUpdate(search) {
    return request({
        url: `/crm/company/list/simpleByUpdate`,
        method: 'get',
        params: {search}
    })
}

/**
 * 添加新公司
 */
export function add(data) {
    return request({
        url: `/crm/company`,
        method: 'post',
        data
    })
}

/**
 * 获取公司详情
 */
export function get(id,) {
    return request({
        url: `/crm/company/${id}`,
        method: 'get'
    })
}

/**
 * 更新公司状态
 */
export function updateStatus(id, status) {
    return request({
        url: `/crm/company/${id}/status/${status}`,
        method: 'put'
    })
}

/**
 * 更新进度
 * @param progress 1. 调研；2. 邮件；3. 电话；4. 社群；5. 影片；6. 拜访；7. 参访；8. 解决方案；9. 成交
 */
export function toggleProgress(id, progress) {
    return request({
        url: `/crm/company/${id}/progress/${progress}/toggle`,
        method: 'put'
    })
}

/**
 * 更新收藏状态
 */
export function toggleFavorite(id) {
    return request({
        url: `/crm/company/${id}/favorite/toggle`,
        method: 'put'
    })
}

/**
 * 更新公司基本信息
 */
export function updateBase(data) {
    return request({
        url: `/crm/company/${data.id}/base`,
        method: 'put',
        data
    })
}

/**
 * 更新公司基本信息
 */
export function updateDomain(data) {
    return request({
        url: `/crm/company/${data.id}/domain`,
        method: 'put',
        data
    })
}

/**
 * 更新客户来源信息
 */
export function updateAcquisition(data) {
    return request({
        url: `/crm/company/${data.id}/acquisition`,
        method: 'put',
        data
    })
}

/**
 * 更新利润预计和负责人
 */
export function updateProjectRevenue(data) {
    return request({
        url: `/crm/company/${data.id}/projectRevenue`,
        method: 'put',
        data
    })
}
