import request from './request';


/**
 * 獲取基礎信息列表
 * @return {json} 列表數據
 */
export function getBasicTypeList() {
  return request({
    url: `/basic/info/basicInfo/type`,
    method: 'get'
  })
}

/**
 * 獲取基礎信息列表
 * @return {json} 列表數據
 */
export function getBasicInfoList(type) {
  return request({
    url: `/basic/info/basicInfo`,
    method: 'get',
    params: {type}
  })
}

/**
 * 獲取貨幣類型列表
 * @return {json} 列表數據
 */
export function getCurrencyList(data) {
  return request({
    url: `/basic/info/currency`,
    method: 'get',
    params: data
  })
}

/**
 * 獲取國家列表
 * @return {json} 列表數據
 */
export function getCountryList(data) {
  return request({
    url: `/basic/info/country`,
    method: 'get',
    params: data
  })
}

/**
 * 獲取省份列表
 * @return {json} 列表數據
 */
export function getProvinceList(countryId, data) {
  return request({
    url: `/basic/info/${countryId}/province`,
    method: 'get',
    params: data
  })
}

/**
 * 獲取城市列表
 * @return {json} 列表數據
 */
export function getCityList(countryId, data) {
  return request({
    url: `/basic/info/${countryId}/city`,
    method: 'get',
    params: data
  })
}

/**
 * 獲取区县列表
 *
 * @param id
 * @param data
 * @returns {json}
 */
export function getDistrictList(countryId, data) {
  return request({
    url: `/basic/info/${countryId}/district`,
    method: 'get',
    params: data
  })
}

/**
 * 獲取乡镇列表
 *
 * @param id
 * @param data
 * @returns {json}
 */
export function getTownList(countryId, data) {
  return request({
    url: `/basic/info/${countryId}/town`,
    method: 'get',
    params: data
  })
}

/**
 * 獲取假日维护列表
 *
 * @param id
 * @param data
 * @returns {json}
 */
export function getHolidayList(data) {
  return request({
    url: `/basic/info/holiday`,
    method: 'get',
    params: data
  })
}