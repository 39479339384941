import request from './request';

export function list(search) {
  return request({
    url: `/crm/video`,
    method: 'get',
    params: {search}
  })
}
export function listByLevel(level, search) {
  return request({
    url: `/crm/video/level/${level}`,
    method: 'get',
    params: {search}
  })
}

export function add(level, data) {
  return request({
    url: `/crm/video/level/${level}`,
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/crm/video/${data.id}`,
    method: 'put',
    data
  })
}

