import request from './request';

export function list(search) {
  return request({
    url: `/crm/file`,
    method: 'get',
    params: {search}
  })
}

export function listByLevel(level, search) {
  return request({
    url: `/crm/file/level/${level}`,
    method: 'get',
    params: {search}
  })
}

export function add(level, data) {
  return request({
    url: `/crm/file/level/${level}`,
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/crm/file/${data.id}`,
    method: 'put',
    data
  })
}

