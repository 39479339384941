export function diffDays(date1, date2) {


    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    // 计算时间差（以毫秒为单位）
    const timeDifference = date1.getTime() - date2.getTime();

    // 将时间差转换为天数
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
}