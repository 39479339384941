import request from "./request";

/**
 * 获取公司详情
 */
export function get(data) {
    return request({
        url: `/crm/companyRelated`,
        method: 'get',
        params: data
    })
}
