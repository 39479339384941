<template>
  <div class="components-page" v-loading="loading">
    <div class="main-wrap flex flex-dc">
      <div class="content-title-wrap flex flex-ac flex-jb">
        <div class="title">拜訪記錄 ({{ contactLogList.length }})</div>
        <div class="tooltip-wrap">
          <el-input placeholder="Search" v-model="search" clearable class="input-with-select">
            <i slot="prepend" class="el-icon-search"></i>
            <div slot="append" class="flex flex-ac">
              <i class="el-icon-sort pointer mr10"></i>
              <i class="el-icon-s-operation pointer"></i>
            </div>
          </el-input>
        </div>
      </div>

      <div class="card-content flex flex-ww">
        <div v-if="!addNew" class="form-card-wrap flex flex-ac flex-jc">
          <i class="el-icon-circle-plus-outline pointer" style="font-size:calc(2vw + 20px); color:#999"
             @click="newContactLog"></i>
        </div>

        <div v-if="addNew" class="form-card-wrap">
          <div class="form-wrap global-form-wrap">
            <div class="flex flex-1">
              <div class="left flex-dc">
                <div class="form-group flex flex-ac">
                  <div class="form-label">*拜訪人</div>
                  <div class="form-control flex-1">
                    <el-select v-model="newItem.contactIdList" placeholder="请选择" size="small" style="width:100%"
                               multiple filterable default-first-option>
                      <el-option v-for="contact in contactList" :key="'cl_newItem_' + contact.id"
                                 :label="contact.name" :value="contact.id"></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="form-group flex flex-ac">
                  <div class="form-label">*拜訪日期</div>
                  <div class="form-control flex-1">
                    <el-date-picker
                        v-model="newItem.timeAt"
                        type="date"
                        size="small"
                        placeholder="请选择"
                        style="width: 100%;"
                        value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                  </div>
                </div>
              </div>

              <div class="pic flex flex-ac ml15">
                <img v-if="newItem.contactList" :src="newItem.contactList[0].avatarUrl" width="60px">
                <img v-else src="@/assets/images/logo.png" width="60px">
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*拜訪地點</div>
              <div class="form-control flex-1">
                <el-input v-model="newItem.address" placeholder="请输入内容" size="small"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*拜訪主題</div>
              <div class="form-control flex-1">
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入内容"
                    v-model="newItem.topic">
                </el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*Memo</div>
              <div class="form-control flex-1">
                <el-input
                    type="textarea"
                    :rows="10"
                    placeholder="请输入内容"
                    v-model="newItem.memo">
                </el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac flex-je">
              <el-button type="success" size="small" @click="save()">Save</el-button>
              <el-button type="info" size="small" @click="addNew=false">Cancels</el-button>
            </div>
          </div>
        </div>

        <div v-for="(contactLog, index) in contactLogList" :key="contactLog.id" class="form-card-wrap">
          <div class="form-wrap global-form-wrap">
            <div class="flex flex-1">
              <div class="left flex-dc">
                <div class="form-group flex flex-ac">
                  <div class="form-label">*拜訪人</div>
                  <div class="form-control flex-1">
                    <el-select v-model="contactLog.contactIdList" placeholder="请选择" size="small"
                               :disabled="!cardStatus['_' + index].isEdit" style="width:100%"
                               multiple filterable default-first-option>
                      <el-option v-for="contact in contactList" :key="'cl_' + index + '_' + contact.id"
                                 :label="contact.name" :value="contact.id"></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="form-group flex flex-ac">
                  <div class="form-label">*拜訪日期</div>
                  <div class="form-control flex-1">
                    <el-date-picker
                        v-model="contactLog.timeAt"
                        type="date"
                        size="small"
                        placeholder="请选择"
                        style="width: 100%;"
                        :disabled="!cardStatus['_' + index].isEdit"
                        value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                  </div>
                </div>
              </div>

              <div class="pic flex flex-ac ml15">
                <img v-if="contactLog.contactList" :src="contactLog.contactList[0].avatarUrl" width="60px">
                <img v-else src="@/assets/images/logo.png" width="60px">
              </div>
              <div class="btn flex flex-dc flex-ac flex-js ml15">
                <i class="el-icon-edit pointer" style="font-size:calc(0.8vw + 8px); color:#555"
                   :class="{'text-success': cardStatus['_' + index].isEdit}"
                   @click="cardStatus['_' + index].isEdit = !cardStatus['_' + index].isEdit"></i>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*拜訪地點</div>
              <div class="form-control flex-1">
                <el-input v-model="contactLog.address" placeholder="请输入内容" size="small"
                          :disabled="!cardStatus['_' + index].isEdit"></el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*拜訪主題</div>
              <div class="form-control flex-1">
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="请输入内容"
                    v-model="contactLog.topic"
                    :disabled="!cardStatus['_' + index].isEdit">
                </el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac">
              <div class="form-label">*Memo</div>
              <div class="form-control flex-1">
                <el-input
                    type="textarea"
                    :rows="10"
                    placeholder="请输入内容"
                    v-model="contactLog.memo"
                    :disabled="!cardStatus['_' + index].isEdit">
                </el-input>
              </div>
            </div>

            <div class="form-group flex flex-ac flex-je" v-show="cardStatus['_' + index].isEdit">
              <el-button type="success" size="small" @click="update(contactLog, index)">Save</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../styles/element-variables.scss';

.components-page::v-deep {
  overflow: hidden;
  height: 100%;

  .main-wrap {
    height: 100%;
    padding: 15px;

    .content-title-wrap {
      border-bottom: 1px solid #999;

      .title {
        font-size: calc(0.6vw + 6px);
        font-weight: bold;
        padding-left: 15px;
      }

      .tooltip-wrap {
        .el-input-group__prepend,
        .el-input__inner,
        .el-input-group__append {
          background-color: transparent;
          border: none;

          i {
            font-size: calc(0.7vw + 7px);
          }
        }
      }
    }

    .card-content {
      margin-top: 10px;
      overflow: auto;

      .form-card-wrap {
        padding: 15px;
        margin: 10px;
        box-shadow: 0 5px 5px rgba(#000, 0.2);
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        width: calc(50% - 20px);
        min-height: 150px;

        &.active {
          background-color: rgba($--color-primary, 0.1);
        }
      }

      .list-wrap {
        .list-item {
          margin-left: 2%;
          width: calc(2.2vw + 22px);
          height: calc(2.2vw + 22px);
          border-radius: 100%;
          border: 4px solid #999;
          color: #999;
          font-size: calc(0.6vw + 5px);
          font-weight: bold;
          cursor: pointer;

          &.active {
            background-color: $--color-primary;
            color: #fff;
            border-color: $--color-primary;
            box-shadow: 0 0 5px #555;
            text-shadow: 0 0 15px #fff;
          }
        }
      }
    }
  }
}
</style>

<script>
import * as contactLogApi from '@/apis/contact-log'
import * as contactApi from '@/apis/contact'

export default {
  props: ["companyId"],
  data() {
    return {
      loading: false,
      contactType: 3,
      cardStatus: {
        _0: {
          isEdit: false
        }
      },
      search: '',
      addNew: false,
      newItem: null,
      contactLogList: [],
      contactList: []
    }
  },
  watch: {
    search: function () {
      this.loadList();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loadList();
      this.loadContact();
    },
    loadList() {
      this.loading = true;

      contactLogApi.listByCompanyIdAndType(this.companyId, this.contactType, this.search).then(data => {
        const cardStatus = {}
        for (let index = 0; index < data.length; index++) {
          cardStatus['_' + index] = {
            isEdit: false
          }
        }
        this.cardStatus = cardStatus;
        this.contactLogList = data;
      }).finally(() => {
        this.loading = false;
      })
    },
    newContactLog() {
      this.newItem = {}
      this.addNew = true;
    },
    save() {
      this.loading = true;

      // 新增
      contactLogApi.add(this.companyId, this.contactType, this.newItem).then(() => {
        this.addNew = false;
        this.loadList();
      }).catch(() => {
        this.loading = false;
      })
    },
    update(contactLog, index) {
      this.loading = true;

      // 更新
      contactLogApi.update(contactLog).then(() => {
        this.cardStatus['_' + index].isEdit = false;
        this.loadList();
      }).catch(() => {
        this.loading = false;
      })
    },
    loadContact() {
      contactApi.listByCompanyId(this.companyId).then(data => {
        this.contactList = data;
      })
    }
  }
}
</script>
